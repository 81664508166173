import React, { useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  Cookies,
  useHistory,
  FormattedMessage,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Modal,
  Backdrop,
  Fade,
  hostAPI,
  PropTypes,
  moment,
  axios,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Box,
  Button,
  Divider,
  Grid,
  OutlinedInput,
  Typography,
  Snackbar,
  CircularProgress,
  authHeader,
  TransactionService,
  InputLabel,
  MenuItem,
  SearchIcon,
  ClearIcon,
  Select,
  FormControl,
  MuiAlert,
  KeyboardDatePicker,
} from "../common";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

let headersss = [
  { label: "First Name", key: "firstname" },
  { label: "Last Name", key: "lastname" },
  { label: "Email", key: "email" },
];

let csvDatas = [
  { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
  { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
];

let Base_URL = `${hostAPI.getHost()}`;

const styless = StyleSheet.create({
  page: {
    padding: "15px",
    fontSize: "12px",
    lineHeight: "1.5",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    padding: 7,
    borderBottom: "1px solid #ccc",
  },
  tableCol: {
    width: "50%",
  },
  tableCell: {
    marginTop: 5,
    color: "#2d335b",
  },
  tableCell2: {
    marginTop: 5,
    color: "#8e9a9d",
    textAlign: "right",
  },
  transactionStyle: {
    color: "#1DBF73",
    marginTop: 5,
    textAlign: "right",
  },
  amountStyle: {
    color: "#008046",
    marginTop: 5,
    textAlign: "right",
  },
  logo: {
    color: "#008046",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  logo2: {
    color: "#2b335e",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "800",
  },
  invoiceTitle: {
    padding: 7,
    fontWeight: "800",
    paddingBottom: "10px",
  },
});

const headCells = [
  // { id: 'color', numeric: false, disablePadding: true, label: 'color' },
  // { id: 'icon', numeric: true, disablePadding: false, label: 'icon' },
  // { id: "title", numeric: true, disablePadding: false, label: "Title" },
  {
    id: "transaction_id",
    numeric: true,
    disablePadding: false,
    label: "Transaction ID",
  },
  {
    id: "sender",
    numeric: true,
    disablePadding: false,
    label: "Transaction With"
  },
  // {
  //   id: "transaction_type",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Type",
  // },
  { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
  { id: "sender_charge", numeric: true, disablePadding: false, label: "Fees" },
  { id: "type", numeric: true, disablePadding: false, label: "Type" },
  { id: "reference", numeric: true, disablePadding: false, label: "Reference" },
  { id: "counter", numeric: true, disablePadding: false, label: "Refund Amount" },
  { id: "remarks", numeric: true, disablePadding: false, label: "Remarks" },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", color: "gray" }}
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Transactions(props) {
  let history = useHistory();

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created_at");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [userTransactions, setUserTransaction] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [errorMsgBody, setErrorMsgBody] = useState("");
  const [open3, setOpen3] = useState(false);
  const [NoData, setNoData] = React.useState(false);

  const [dataTableIsLoading, setDataTableIsLoading] = React.useState(true);

  const [tx_with, setTx_with] = React.useState("");
  const [searchid, setSearchid] = React.useState("");
  const [searchamount, setSearchamount] = React.useState("");
  const [searchtype, setSearchtype] = React.useState("");
  const [searchstatus, setSearchstatus] = React.useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [transType, setTransType] = React.useState([]);
  const [transSatus, setTransSatus] = React.useState([]);

  const [show, setShow] = React.useState(false);

  const [loadingPrint, setLoadingPrint] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);

  const handlePrintClickOpen = () => {
    setOpenPrint(true);
  };

  const handlePrintClose = () => {
    setOpenPrint(false);
  };

  useEffect(() => {
    handleSearch();

    TransactionService.getTransactionType().then(
      (response) => {
        if (response.data.code === 200) {
          if (response.data.data.transaction_types.length > 0) {
            setTransType(response.data.data.transaction_types);
          }
        }
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        }
      }
    );

    TransactionService.getTransactionStatus().then(
      (response) => {
        if (response.data.code === 200) {
          if (response.data.data.transaction_statuses.length > 0) {
            setTransSatus(response.data.data.transaction_statuses);
          }
        }
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        }
      }
    );
  }, [page]);

  const handleFilter = () => {
    setShow(!show);
  };

  const handleSearch = async () => {
    let fromDate_new = fromDate;
    let toDate_new = toDate;
    if (!fromDate) {
      fromDate_new = "";
    }
    if (!toDate) {
      toDate_new = "";
    }
    setDataTableIsLoading(true);
    TransactionService.getTransactions(
      searchid,
      tx_with,
      searchamount,
      searchtype,
      searchstatus,
      fromDate_new,
      toDate_new,
      page + 1
    ).then(
      (response) => {
        if (response.data.code === 200) {
          if (response.data.data !== null) {
            setDataTableIsLoading(false);
            setTotalRows(
              response &&
                response.data &&
                response.data.data_additional &&
                response.data.data_additional.total
            );
            setRowsPerPage(
              response &&
                response.data &&
                response.data.data_additional &&
                response.data.data_additional.per_page
            );

            setTotalPage(
              Math.ceil(
                response.data.data_additional.total /
                  response.data.data_additional.per_page
              )
            );
            setUserTransaction(
              response &&
                response.data &&
                response.data.data &&
                response.data.data.transactions
            );

            // console.log("tran all data", response.data);
            // console.log("tran", response.data.data.transactions);
          } else if (response.data.data === null) {
            document.getElementById("noTrans").style.display = "none";
            setNoData(true);
          }
        } else {
          setDataTableIsLoading(false);
          setOpen3(true);
          setErrorMsgBody(response && response.data && response.data.messages);
        }
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        } else if (
          userTransactions === error.response ||
          error.response.data ||
          error.response.data.message ||
          error.message ||
          error.toString()
        ) {
          Cookies.remove("Mtoken");
          localStorage.clear();
          history.push("/login");
          window.location.reload();
        }
      }
    );
  };

  const clearFormInput = () => {
    setSearchid("");
    setSearchtype("");
    setSearchamount("");
    setSearchstatus("");
    setTx_with("");
    setFromDate(null);
    setToDate(null);

    handleSearch();
  };

  // const handlePrint = async () => {
  //   setLoadingPrint(true);
  //   let myArray = [];

  //   console.log("csv data", myArray);

  //   let headers = [
  //     { label: "Title", key: "title" },
  //     { label: "Transaction Id", key: "transaction_id" },
  //     { label: "Amount", key: "amount" },
  //     { label: "Fees", key: "sender_charge" },
  //     { label: "Currency", key: "currency" },
  //     { label: "Transaction Type", key: "transaction_type" },
  //     { label: "Date", key: "created_at" },
  //     { label: "Transaction With", key: "destination" },
  //     { label: "Type", key: "type_of_tx" },
  //   ];

  //   setCsvHeader(headers);

  //   for (let i = 0; i < totalPage; i++) {
  //     TransactionService.getTransactions(
  //       searchid,
  //       tx_with,
  //       searchamount,
  //       searchtype,
  //       searchstatus,
  //       fromDate,
  //       toDate,
  //       1 + i
  //     ).then((response) => {
  //       if (response.data.code === 200) {
  //         console.log("dd tran", response.data.data.transactions);
  //         response &&
  //           response &&
  //           response.data &&
  //           response.data.data &&
  //           response.data.data.transactions.map((e) => {
  //             let data = {};
  //             data.title = e.title;
  //             data.transaction_id = e.transaction_id;
  //             data.amount = e.amount;
  //             data.sender_charge = e.new.fee;
  //             data.currency = e.currency;
  //             data.transaction_type = e.new.type;
  //             data.created_at = e.created_at;
  //             data.destination =
  //               (e.destination && e.destination.mobile_no) ||
  //               (e.source && e.source.mobile_no);
  //             data.type_of_tx = e.type_of_tx;
  //             myArray.push(data);
  //             console.log("all dddd", i, totalPage, data);
  //           });
  //       } else {
  //         console.log("something is wrong");
  //         setLoadingPrint(false);
  //         handlePrintClose();
  //       }
  //     });
  //     if (i + 1 === totalPage) {
  //       setTimeout(() => {
  //         setCsvData(myArray);
  //         setLoadingPrint(false);
  //         setShowPrint(true);
  //       }, 2000);
  //     }
  //   }
  // };

  const handlePrint = async () => {
    setLoadingPrint(true);
    let myArray = [];


    let headers = [
      { label: "Title", key: "title" },
      { label: "Transaction Id", key: "transaction_id" },
      { label: "Amount", key: "amount" },
      { label: "Fees", key: "sender_charge" },
      { label: "Currency", key: "currency" },
      { label: "Transaction Type", key: "transaction_type" },
      { label: "Reference Id", key: "reference_id" },
      { label: "Remarks", key: "remarks" },
      { label: "Date", key: "created_at" },
      { label: "Transaction With", key: "destination" },
      { label: "Type", key: "type_of_tx" },
    ];

    setCsvHeader(headers);

    for (let i = 0; i < totalPage; i++) {
      TransactionService.getTransactions(
        searchid,
        tx_with,
        searchamount,
        searchtype,
        searchstatus,
        fromDate,
        toDate,
        1 + i
      ).then((response) => {
        if (response.data.code === 200) {
          response &&
            response &&
            response.data &&
            response.data.data &&
            response.data.data.transactions.map((e) => {
              let data = {};
              data.title = e.title;
              data.transaction_id = e.transaction_id;
              data.amount = e.amount;
              data.sender_charge = e.new.fee;
              data.currency = e.currency;
              data.transaction_type = e.new.type;
              data.reference_id = e.new.reference;
              data.remarks = e.new.remarks;
              data.created_at = e.created_at;
              data.destination =
                (e.destination && e.destination.mobile_no) ||
                (e.source && e.source.mobile_no);
              data.type_of_tx = e.type_of_tx;
              myArray.push(data);
              // console.log("all dddd", i, totalPage, data);
            });
        } else {
          console.log("something is wrong");
          setLoadingPrint(false);
          handlePrintClose();
        }
      });
      if (i + 1 === totalPage) {
        setTimeout(() => {
          setCsvData(myArray);
          setLoadingPrint(false);
          setShowPrint(true);
        }, 2000);
      }
    }
  };

  const changeFromDate = (date) => {
    let formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    setFromDate(formattedDate);
  };

  const changeToDate = (date) => {
    let formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    setToDate(formattedDate);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userTransactions.map((n) => n.transaction_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [otherData, setOtherData] = useState("");
  const [userTrans, setUserTrans] = useState("");

  const handleSubmitModel = (event, transaction_id, fullData) => {
    event.preventDefault();
    setOpen(true);

    axios
      .get(
        `${Base_URL}api/v1/private/user/transaction/invoice?invoice_id=${transaction_id}&lang=${localStorage.getItem(
          "MnewLocale"
        )}`,
        { headers: authHeader() }
      )
      .then(
        (response) => {
          if (response.data.data !== null) {
            setUserTrans(response.data.data);
            setOtherData(fullData);
          }
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            Cookies.remove("Mtoken");
            localStorage.clear();
            history.push("/login");
            window.location.reload();
          }
        },
        [userTrans]
      );
  };

  const downloadInvoice = (event, transaction_id) => {
    event.preventDefault();
    setOpen1(true);

    axios
      .get(
        `${Base_URL}api/v1/private/user/transaction/invoice?invoice_id=${transaction_id}&lang=${localStorage.getItem(
          "MnewLocale"
        )}`,
        { headers: authHeader() }
      )
      .then(
        (response) => {
          if (response.data.data !== null) {
            setUserTrans(response.data.data);
          }
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            Cookies.remove("Mtoken");
            localStorage.clear();
            history.push("/login");
            window.location.reload();
          }
        },
        [userTrans]
      );
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <div id="content">
        <div>
          <Snackbar
            open={open3}
            autoHideDuration={4000}
            onClose={handleClose3}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose3} severity="error">
              {errorMsgBody}
            </Alert>
          </Snackbar>
          <div
            className="row"
            style={{ textAlign: `${localStorage.getItem("LocalTextAlign")}` }}
          >
            <div className="col-lg-12">
              {/* <CSVDownload data={csvData} target="_blank" />; */}

              <Button
                variant="contained"
                color="secondary"
                className="btn btn-sm btn btn-primary"
                style={{
                  borderRadius: "50px",
                  float: "right",
                  marginRight: "1%",
                  marginBottom: "1%",
                }}
                onClick={(e) => {
                  setOpenPrint(true);
                  handlePrint();
                }}
              >
                <GetAppIcon />
                CSV
              </Button>

              <Dialog
                open={openPrint}
                onClose={handlePrintClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ textAlign: "center", width: 500 }}
                  >
                    {loadingPrint ? (
                      <div>
                        <CircularProgress size={50} />
                        <h4>Please wait...</h4>
                      </div>
                    ) : (
                      <h4>Download Transaction List!</h4>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CSVLink
                    data={csvData}
                    headers={csvHeader}
                    filename="transaction.csv"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className="btn btn-sm btn btn-primary"
                      style={{
                        borderRadius: "50px",
                        marginRight: "1%",
                        marginBottom: "1%",
                      }}
                      onClick={handlePrintClose}
                      disabled={loadingPrint}
                    >
                      <GetAppIcon />
                      Download
                    </Button>
                  </CSVLink>
                </DialogActions>
              </Dialog>

              {/* <CSVLink
                data={csvData}
                headers={csvHeader}
                filename="transaction.xlsx"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  className="btn btn-sm btn btn-primary"
                  style={{
                    borderRadius: "50px",
                    float: "right",
                    marginRight: "1%",
                    marginBottom: "1%",
                  }}
                >
                  <GetAppIcon />XLSX
                </Button>
              </CSVLink> */}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleFilter}
                className="btn btn-sm btn btn-primary"
                style={{
                  borderRadius: "50px",
                  float: "right",
                  marginRight: "1%",
                  marginBottom: "1%",
                }}
              >
                <SearchIcon />
                <FormattedMessage id="Filter" defaultMessage="Filter" />
              </Button>
            </div>
            <div className="col-lg-12">
              {NoData && (
                <div>
                  <p>
                    <FormattedMessage
                      id="No_Transactions"
                      defaultMessage="No Transactions Yet"
                    />
                  </p>
                </div>
              )}

              <div id="noTrans">
                {show ? (
                  <Paper style={{ padding: "20px" }}>
                    <Typography
                      style={{
                        textAlign: "left",
                        marginBottom: "10px",
                        fontSize: "1.125rem",
                        letterSpacing: "0.23px",
                        fontWeight: "500",
                        color: "#2B335E",
                      }}
                    >
                      <FormattedMessage
                        id="Filter_By"
                        defaultMessage="Filter By"
                      />
                      :
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item xs={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: "3%" }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            <FormattedMessage id="ID" defaultMessage="ID" />
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            label="ID"
                            type="text"
                            inputComponent="input"
                            id="searchid"
                            value={searchid}
                            onChange={(e) => setSearchid(e.target.value)}
                          />
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: "3%", marginTop: 8 }}
                          size="small"
                          id="merchant"
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            <FormattedMessage
                              id="app_common_amount"
                              defaultMessage="Amount"
                            />
                          </InputLabel>
                          <OutlinedInput
                            labelid="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            label="Merchant"
                            value={searchamount}
                            onChange={(e) => setSearchamount(e.target.value)}
                          ></OutlinedInput>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: "3%" }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            <FormattedMessage
                              id="TransactionWith"
                              defaultMessage="Transaction With"
                            />
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            label="From Amount"
                            type="text"
                            inputComponent="input"
                            id="tx_with"
                            value={tx_with}
                            onChange={(e) => setTx_with(e.target.value)}
                          />
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="medium"
                          id="fromDate"
                          style={{ marginTop: "0" }}
                        >
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            disableFuture
                            invalidDateMessage=""
                            margin="dense"
                            id="fromDate"
                            placeholder="From Date"
                            format="YYYY-MM-DD"
                            value={fromDate}
                            onChange={changeFromDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: "3%" }}
                          size="small"
                          label="Reciever is"
                          id="searchtype"
                        >
                          <InputLabel id="transType">Type </InputLabel>
                          <Select
                            labelid="transType"
                            id="demo-simple-select-filled"
                            value={searchtype ? searchtype : " "}
                            label="Reciever"
                            onChange={(e) => setSearchtype(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>
                                <FormattedMessage
                                  id="None"
                                  defaultMessage="None"
                                />
                              </em>
                            </MenuItem>
                            {transType.map((val) => (
                              <MenuItem key={val.id} value={val.id}>
                                {
                                  (val.name === "Online-Shopping") ? <>Ecom Payment</> : 
                                  (val.name === "Physical-Shop") ? <>Make Payment</> : <>{val.name.replace("-"," ")}</>
                                }
                              </MenuItem>

                            ))}
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="medium"
                          id="toDate"
                          style={{ marginTop: "0" }}
                        >
                          <KeyboardDatePicker
                            inputVariant="outlined"
                            disableFuture
                            invalidDateMessage=""
                            margin="dense"
                            id="toDate"
                            placeholder="To Date"
                            format="YYYY-MM-DD"
                            value={toDate}
                            onChange={changeToDate}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={3}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          id="searchstatus"
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            <FormattedMessage
                              id="status"
                              defaultMessage="Status"
                            />
                          </InputLabel>
                          <Select
                            labelid="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={searchstatus ? searchstatus : " "}
                            onChange={(e) => setSearchstatus(e.target.value)}
                            label="Status"
                          >
                            <MenuItem value="">
                              <em>
                                <FormattedMessage
                                  id="None"
                                  defaultMessage="None"
                                />
                              </em>
                            </MenuItem>
                            {transSatus.map((Tstatus) => (
                              <MenuItem
                                key={Tstatus.identifier}
                                value={Tstatus.identifier}
                              >
                                {Tstatus.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider style={{ margin: "10px 0" }} />
                    <Grid
                      item
                      container
                      xl={12}
                      justify="flex-end"
                      alignItems="flex-end"
                    >
                      <Box mr={5}>
                        <Button
                          variant="contained"
                          className="btn btn-sm"
                          style={{ borderRadius: "50px" }}
                          onClick={clearFormInput}
                        >
                          <ClearIcon />{" "}
                          <FormattedMessage id="Clear" defaultMessage="Clear" />
                        </Button>
                      </Box>
                      <Box mr={3} mt={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={(e) => {
                            setPage(0);
                            handleSearch(e);
                          }}
                          className="btn btn-sm btn btn-primary"
                          style={{ borderRadius: "50px" }}
                        >
                          <SearchIcon />
                          <FormattedMessage
                            id="Filter"
                            defaultMessage="Filter"
                          />
                        </Button>
                      </Box>
                    </Grid>
                  </Paper>
                ) : null}

                <Paper>
                  {dataTableIsLoading ? (
                    <Box mt={15} style={{ textAlign: "center" }}>
                      <CircularProgress
                        size={40}
                        style={{ color: "#008046" }}
                        thickness={3}
                      />
                    </Box>
                  ) : (
                    <div>
                      <TableContainer>
                        <Table
                          aria-labelledby="tableTitle"
                          size={"medium"}
                          aria-label="enhanced table"
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={userTransactions.length}
                          />

                          <TableBody>
                            {userTransactions && userTransactions.length <= 0 && (
                              <TableRow>
                                <TableCell
                                  className="p-4"
                                  style={{ color: "gray" }}
                                >
                                  <small
                                    style={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    No data found!
                                  </small>
                                </TableCell>
                              </TableRow>
                            )}

                            {userTransactions &&
                              userTransactions.length > 0 &&
                              userTransactions.map((row, index) => (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.transaction_id}
                                >
                                  <TableCell align="center">
                                    <small
                                      style={{
                                        color: "#008046",
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) =>
                                        handleSubmitModel(
                                          event,
                                          row.transaction_id,
                                          row
                                        )
                                      }
                                    >
                                      {row.transaction_id}
                                    </small>

                                    <Modal
                                      className="ModalStyle"
                                      aria-labelledby="transition-modal-title"
                                      aria-describedby="transition-modal-description"
                                      open={open}
                                      onClose={handleClose}
                                      closeAfterTransition
                                      BackdropComponent={Backdrop}
                                      BackdropProps={{
                                        timeout: 500,
                                      }}
                                    >
                                      <Fade in={open}>
                                        <div>
                                          <div
                                            className="modal-dialog modal-dialog-centered transaction-details"
                                            role="document"
                                          >
                                            <div className="modal-content">
                                              <div className="modal-body">
                                                <div className="row no-gutters">
                                                  <div className="col-sm-5 d-flex justify-content-center bg-primary rounded-left py-4">
                                                    <div className="my-auto text-center">
                                                      <div className="text-17 text-white my-3">
                                                        {" "}
                                                        {userTrans.recipient ? (
                                                          <div>
                                                            <ul className="list-unstyled">
                                                              <li className="text-muted">
                                                                <div>
                                                                  {/* image  */}
                                                                  {/* {userTrans
                                                                    .recipient
                                                                    .avatar ? (
                                                                    <img
                                                                      src={
                                                                        userTrans
                                                                          .recipient
                                                                          .avatar
                                                                      }
                                                                      alt="invoice avatar"
                                                                      style={{
                                                                        border:
                                                                          "1px solid #ec247c",
                                                                        borderRadius:
                                                                          "50%",
                                                                        width:
                                                                          "100px",
                                                                        height:
                                                                          "100px",
                                                                      }}
                                                                    />
                                                                  ) : null} */}


                                                                  <img
                                                                      src={otherData.icon}
                                                                      alt="Avatar"
                                                                      style={{
                                                                        border:"1px solid #ec247c",
                                                                        borderRadius:"50%",
                                                                        width:"100px",
                                                                        height: "100px"
                                                                      }}
                                                                    />
                                                                </div>
                                                              </li>
                                                            </ul>

                                                            <h3 className="text-4 text-white font-weight-400 my-3">
                                                              {otherData.transaction_type === "Regular Transfer (BEFTN)" 
                                                              ? 
                                                                <>{otherData.new.bank} </>
                                                              : 
                                                                <>{userTrans.recipient.title}</>
                                                        
                                                              }
                                                            </h3>
                                                            <h3
                                                              className="text-4 text-white font-weight-400 my-3"
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >

                                                              {otherData.transaction_type === "Regular Transfer (BEFTN)" 
                                                              ? 
                                                                <>{row.new.title}</>
                                                              : 
                                                                <>{userTrans.recipient.name}</>
                                                        
                                                              }
                                                            </h3>
                                                          </div>
                                                        ) : null}
                                                      </div>

                                                      <div className="text-8 font-weight-500 text-white my-4">
                                                        {
                                                          userTrans.transaction_amount
                                                        }
                                                      </div>
                                                      <p className="text-white">
                                                        {userTrans.date}
                                                      </p>
                                                      <p
                                                        className="text-white"
                                                        style={{
                                                          cursor: "pointer",
                                                          textDecoration:
                                                            "underline",
                                                        }}
                                                        onClick={(e) =>
                                                          downloadInvoice(
                                                            e,
                                                            userTrans.transaction_id
                                                          )
                                                        }
                                                      >
                                                        Download Pdf
                                                      </p>
                                                      <p>
                                                        {userTrans.refundable ? (
                                                          <p
                                                            className="text-white"
                                                            style={{
                                                              cursor: "pointer",
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                            onClick={(e) =>
                                                              history.push(
                                                                `/refund?tran_id=${userTrans.transaction_id}`
                                                              )
                                                            }
                                                          >
                                                            Refund Money
                                                          </p>
                                                        ) : null}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-sm-7">
                                                    <h5 className="text-5 font-weight-400 m-3">
                                                      <FormattedMessage
                                                        id="Transaction_Details"
                                                        defaultMessage="Transaction Details"
                                                      />

                                                      <button
                                                        type="button"
                                                        className="close font-weight-400"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={handleClose}
                                                      >
                                                        {" "}
                                                        <span aria-hidden="true">
                                                          &times;
                                                        </span>{" "}
                                                      </button>
                                                    </h5>
                                                    <hr />
                                                    <div className="px-3">
                                                      <ul className="list-unstyled">
                                                        <li className="mb-2">
                                                          <FormattedMessage
                                                            id="Payment_Amount"
                                                            defaultMessage="Payment Amount"
                                                          />{" "}
                                                          <span className="float-right text-3">
                                                            {
                                                              userTrans.transaction_amount
                                                            }
                                                          </span>
                                                        </li>
                                                        <li className="mb-2">
                                                          <FormattedMessage
                                                            id="Fee"
                                                            defaultMessage="Fee"
                                                          />{" "}
                                                          <span className="float-right text-3">
                                                            {
                                                              userTrans.transaction_fee
                                                            }
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <hr className="mb-2" />
                                                      <p className="d-flex align-items-center font-weight-500 mb-1">
                                                        <FormattedMessage
                                                          id="Total_Amount"
                                                          defaultMessage="Total Amount"
                                                        />{" "}
                                                        <span className="text-3 ml-auto">
                                                          {
                                                            userTrans.total_deduction
                                                          }
                                                        </span>
                                                      </p>
                                                      <p className="d-flex align-items-center mb-4">
                                                        <FormattedMessage
                                                          id="Total_Refund"
                                                          defaultMessage="Total Refund Amount"
                                                        />{" "}
                                                        <span className="text-3 ml-auto">
                                                          {
                                                            userTrans.refund_amount
                                                          }
                                                        </span>
                                                      </p>
                                                      <ul className="list-unstyled">
                                                        <li className="font-weight-500">
                                                          <FormattedMessage
                                                            id="TransactionID"
                                                            defaultMessage="Transaction ID"
                                                          />
                                                          :
                                                        </li>
                                                        <li className="text-muted">
                                                          {
                                                            userTrans.transaction_id
                                                          }
                                                        </li>
                                                      </ul>

                                                      <ul className="list-unstyled">
                                                        <li className="font-weight-500">
                                                          <FormattedMessage
                                                            id="description"
                                                            defaultMessage="Description"
                                                          />
                                                          :
                                                        </li>
                                                        <li className="text-muted">
                                                          {
                                                            userTrans.nature_of_transaction
                                                          }{" "}
                                                          -{" "}
                                                          {
                                                            userTrans.transaction_type
                                                          }
                                                        </li>
                                                      </ul>
                                                      <ul className="list-unstyled">
                                                        <li className="font-weight-500">
                                                          <FormattedMessage
                                                            id="remarks"
                                                            defaultMessage="Remarks"
                                                          />
                                                          :
                                                        </li>
                                                        <li className="text-muted">
                                                          {(userTrans.remarks ? userTrans.remarks : "-")}
                                                        </li>
                                                      </ul>
                                                      <ul className="list-unstyled">
                                                        <li className="font-weight-500">
                                                          <FormattedMessage
                                                            id="date"
                                                            defaultMessage="Date"
                                                          />
                                                          :
                                                        </li>
                                                        <li className="text-muted">
                                                          {userTrans.date}
                                                        </li>
                                                      </ul>
                                                      {userTrans.recipient ? (
                                                        <div>
                                                          <ul className="list-unstyled">
                                                            <li className="font-weight-500">
                                                              <FormattedMessage
                                                                id="Mobile_No"
                                                                defaultMessage="Mobile / Account No."
                                                              />{" "}
                                                              :
                                                            </li>
                                                            <li className="text-muted">
                                                              {" "}
                                                              {otherData.transaction_type === "Regular Transfer (BEFTN)" ? <>
                                                                {otherData.new.title}
                                                              </> : <>
                                                                {userTrans
                                                                .recipient
                                                                .msisdn
                                                                ? userTrans
                                                                    .recipient
                                                                    .msisdn
                                                                : null}
                                                              </> }
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      ) : null}

                                                      {userTrans.card ? (
                                                        <div>
                                                          <ul className="list-unstyled">
                                                            <li className="font-weight-500">
                                                              <FormattedMessage
                                                                id="Card_Type"
                                                                defaultMessage="Card Type"
                                                              />{" "}
                                                              :
                                                            </li>
                                                            <li className="text-muted">
                                                              {" "}
                                                              {userTrans.card
                                                                .type
                                                                ? userTrans.card
                                                                    .type
                                                                : null}
                                                            </li>
                                                          </ul>

                                                          <ul className="list-unstyled">
                                                            <li className="font-weight-500">
                                                              <FormattedMessage
                                                                id="Card_Type"
                                                                defaultMessage="Card Type"
                                                              />{" "}
                                                              :
                                                            </li>
                                                            <li className="text-muted">
                                                              {" "}
                                                              {userTrans.card
                                                                .how_to
                                                                ? userTrans.card
                                                                    .how_to
                                                                : null}
                                                            </li>
                                                          </ul>

                                                          <ul className="list-unstyled">
                                                            <li className="text-muted">
                                                              {" "}
                                                              {userTrans.card
                                                                .thumbnail ? (
                                                                <img
                                                                  width={200}
                                                                  src={
                                                                    userTrans
                                                                      .card
                                                                      .thumbnail
                                                                  }
                                                                  alt=""
                                                                  style={{
                                                                    border:
                                                                      "2px solid #ec247c",
                                                                    borderRadius:
                                                                      "3px",
                                                                  }}
                                                                />
                                                              ) : null}
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      ) : null}

                                                      {/* PDF Model */}
                                                      <Modal
                                                        className="ModalStyle"
                                                        aria-labelledby="transition-modal-title"
                                                        aria-describedby="transition-modal-description"
                                                        open={open1}
                                                        onClose={handleClose1}
                                                        closeAfterTransition
                                                        BackdropComponent={
                                                          Backdrop
                                                        }
                                                        BackdropProps={{
                                                          timeout: 500,
                                                        }}
                                                      >
                                                        <Fade in={open1}>
                                                          <div>
                                                            <div
                                                              className="modal-dialog modal-dialog-centered transaction-details"
                                                              role="document"
                                                            >
                                                              <div className="modal-content">
                                                                <div className="modal-body">
                                                                  <PDFViewer
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      height: 600,
                                                                    }}
                                                                  >
                                                                    <Document>
                                                                      <Page
                                                                        size="A4"
                                                                        style={
                                                                          styless.page
                                                                        }
                                                                      >
                                                                        <View>
                                                                          <Text>
                                                                            <Text
                                                                              style={
                                                                                styless.logo
                                                                              }
                                                                            >
                                                                              ST Pay
                                                                            </Text>
                                                                            <Text
                                                                              style={
                                                                                styless.logo2
                                                                              }
                                                                            >
                                                                              Pay
                                                                            </Text>
                                                                          </Text>
                                                                        </View>

                                                                        <Text
                                                                          style={
                                                                            styless.invoiceTitle
                                                                          }
                                                                        >
                                                                          INVOICE
                                                                        </Text>

                                                                        <View
                                                                          style={
                                                                            styless.table
                                                                          }
                                                                        >
                                                                          <View
                                                                            style={
                                                                              styless.tableRow
                                                                            }
                                                                          >
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.recipient &&
                                                                                  userTrans
                                                                                    .recipient
                                                                                    .title}
                                                                              </Text>
                                                                            </View>
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell2
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.recipient &&
                                                                                  userTrans
                                                                                    .recipient
                                                                                    .name}
                                                                              </Text>
                                                                            </View>
                                                                          </View>

                                                                          <View
                                                                            style={
                                                                              styless.tableRow
                                                                            }
                                                                          >
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell
                                                                                }
                                                                              >
                                                                                Date:
                                                                              </Text>
                                                                            </View>
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell2
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.date}
                                                                              </Text>
                                                                            </View>
                                                                          </View>

                                                                          <View
                                                                            style={
                                                                              styless.tableRow
                                                                            }
                                                                          >
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell
                                                                                }
                                                                              >
                                                                                Transaction
                                                                                ID:
                                                                              </Text>
                                                                            </View>
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.transactionStyle
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.transaction_id}
                                                                              </Text>
                                                                            </View>
                                                                          </View>

                                                                          <View
                                                                            style={
                                                                              styless.tableRow
                                                                            }
                                                                          >
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell
                                                                                }
                                                                              >
                                                                                Description:
                                                                              </Text>
                                                                            </View>
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.transactionStyle
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.nature_of_transaction}{" "}
                                                                                {
                                                                                  " - "
                                                                                }{" "}
                                                                                {userTrans &&
                                                                                  userTrans.transaction_type}
                                                                              </Text>
                                                                            </View>
                                                                          </View>

                                                                          {userTrans &&
                                                                            userTrans.recipient && (
                                                                              <View
                                                                                style={
                                                                                  styless.tableRow
                                                                                }
                                                                              >
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell
                                                                                    }
                                                                                  >
                                                                                    Recipient
                                                                                    Name:
                                                                                  </Text>
                                                                                </View>
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell2
                                                                                    }
                                                                                  >
                                                                                    {userTrans &&
                                                                                      userTrans.recipient &&
                                                                                      userTrans
                                                                                        .recipient
                                                                                        .name}
                                                                                  </Text>
                                                                                </View>
                                                                              </View>
                                                                            )}
                                                                          {userTrans &&
                                                                            userTrans.recipient && (
                                                                              <View
                                                                                style={
                                                                                  styless.tableRow
                                                                                }
                                                                              >
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell
                                                                                    }
                                                                                  >
                                                                                    Mobile
                                                                                    Number:
                                                                                  </Text>
                                                                                </View>
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell2
                                                                                    }
                                                                                  >
                                                                                    {userTrans &&
                                                                                      userTrans.recipient &&
                                                                                      userTrans
                                                                                        .recipient
                                                                                        .msisdn}
                                                                                  </Text>
                                                                                </View>
                                                                              </View>
                                                                            )}

                                                                          {userTrans &&
                                                                            userTrans.card && (
                                                                              <View
                                                                                style={
                                                                                  styless.tableRow
                                                                                }
                                                                              >
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell
                                                                                    }
                                                                                  >
                                                                                    Card
                                                                                    Type:
                                                                                  </Text>
                                                                                </View>
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell2
                                                                                    }
                                                                                  >
                                                                                    {userTrans &&
                                                                                      userTrans.card &&
                                                                                      userTrans
                                                                                        .card
                                                                                        .type}
                                                                                  </Text>
                                                                                </View>
                                                                              </View>
                                                                            )}

                                                                          {userTrans &&
                                                                            userTrans.card && (
                                                                              <View
                                                                                style={
                                                                                  styless.tableRow
                                                                                }
                                                                              >
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell
                                                                                    }
                                                                                  >
                                                                                    How
                                                                                    to:
                                                                                  </Text>
                                                                                </View>
                                                                                <View
                                                                                  style={
                                                                                    styless.tableCol
                                                                                  }
                                                                                >
                                                                                  <Text
                                                                                    style={
                                                                                      styless.tableCell2
                                                                                    }
                                                                                  >
                                                                                    {userTrans &&
                                                                                      userTrans.card &&
                                                                                      userTrans
                                                                                        .card
                                                                                        .how_to}
                                                                                  </Text>
                                                                                </View>
                                                                              </View>
                                                                            )}

                                                                          <View
                                                                            style={
                                                                              styless.tableRow
                                                                            }
                                                                          >
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell
                                                                                }
                                                                              >
                                                                                Amount:
                                                                              </Text>
                                                                            </View>
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell2
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.transaction_amount}
                                                                              </Text>
                                                                            </View>
                                                                          </View>

                                                                          <View
                                                                            style={
                                                                              styless.tableRow
                                                                            }
                                                                          >
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell
                                                                                }
                                                                              >
                                                                                Fee:
                                                                              </Text>
                                                                            </View>
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell2
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.transaction_fee}
                                                                              </Text>
                                                                            </View>
                                                                          </View>

                                                                          <View
                                                                            style={
                                                                              styless.tableRow
                                                                            }
                                                                          >
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.tableCell
                                                                                }
                                                                              >
                                                                                Total
                                                                                Amount:
                                                                              </Text>
                                                                            </View>
                                                                            <View
                                                                              style={
                                                                                styless.tableCol
                                                                              }
                                                                            >
                                                                              <Text
                                                                                style={
                                                                                  styless.amountStyle
                                                                                }
                                                                              >
                                                                                {userTrans &&
                                                                                  userTrans.total_deduction}
                                                                              </Text>
                                                                            </View>
                                                                          </View>
                                                                        </View>
                                                                      </Page>
                                                                    </Document>
                                                                  </PDFViewer>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Fade>
                                                      </Modal>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div></div>
                                        </div>
                                      </Fade>
                                    </Modal>
                                  </TableCell>


                                  {/* Transaction With Data */}
                                  <TableCell align="center" style={{width: "250px"}}>
                                    <div style={{ display: "flex" }}>
                                      <img
                                        src={row.icon}
                                        alt="icon"
                                        width={37}
                                        height={40}
                                        style={{ marginRight: "5px" }}
                                      />
                                      <div>
                                      
                                      {row && (row.transaction_type === "Regular Transfer (BEFTN)") ? 
                                        <div style={{textAlign: "left"}}>
                                          {row.new.bank ? row.new.bank : ""}
                                          <br />
                                          {row.new.title ? row.new.title : ""}
                                        </div>
                                        :
                                        <>
                                          {row.source ? row.source.name : row.destination.name}
                                          <br />
                                          {row.source ? row.source.mobile_no : row.destination.mobile_no}
                                        </>
                                      }
                                      </div>
                                    </div>
                                  </TableCell>

                                  {/* <TableCell align="center">
                                    {row.transaction_type} 
                                  </TableCell> */}
                                  <TableCell align="center">
                                    {row.amount}
                                    {/* &#2547; */}
                                    {/* {row.currency} */}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.new && row.new.fee}
                                  </TableCell>
                                  
                                  
                                  <TableCell
                                    align="center"
                                    style={{
                                      color: (row.new.type === "Make Payment" ? "#0052CC" : row.color),
                                      padding: "16px 0",
                                    }}
                                  >
                                    <span className="statusDiv">
                                      {row.new.type}
                                    </span>
                                  </TableCell>

                                  <TableCell align="center">
                                    {row.new.reference ? row.new.reference : "-"}
                                  </TableCell>
                                  
                                  <TableCell align="center">
                                    {row.refund_amount ? row.refund_amount : "-"}
                                  </TableCell>

                                  <TableCell align="center">
                                    {
                                      row.new.remarks ? 
                                      <>
                                        {row.new.remarks.substring(0,20)}
                                        {row.new.remarks.length > 20 && <> ...</>}
                                    </> : "-"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.created_at}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        component="div"
                        count={totalRows}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10]}
                        style={{ direction: "ltr" }}
                      />
                    </div>
                  )}
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}